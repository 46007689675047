import React from "react"
import Layout from "../components/layout"
import classes from "./project.module.scss"
import SEO from "../components/seo"
import {graphql } from "gatsby"
import Img from "gatsby-image"
import { SRLWrapper } from "simple-react-lightbox"
import { node } from "prop-types"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"

export const query = graphql`
  query($slug: String!, $locale: String!) {
    contentfulProject(nazwa: { eq: $slug }, node_locale: { eq: $locale }) {
      nazwa
      adres
      node_locale
      description {
        description
      }
      cover {
        fluid(maxHeight: 2000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      images {
        fluid(maxHeight: 400) {
          ...GatsbyContentfulFluid
        }
        file {
          url
        }
      }
    }
  }
`

const project = props => {
  
  return (
    <Layout>
      <SEO title={props.data.contentfulProject.nazwa} />
  
      <div
        className={`${classes.cover} ${"animate"}`}
        data-animation="paralax"
        data-speed=".1"
      >
        <Img
          className={`${classes.cover__img} ${""}`}
          alt="zdjecie storny"
          fluid={props.data.contentfulProject.cover.fluid}
        ></Img>
      </div>
      <section className={classes.card}>
        <h2>{props.data.contentfulProject.nazwa}</h2>
        <p>{props.data.contentfulProject.description.description}</p>
        {props.data.contentfulProject.adres ? (
          <a
            href={props.data.contentfulProject.adres}
            className="btn--neo-background-2 animate"
            data-animation="neo-background-2"
            target="blank"
          >
            <FormattedMessage id="btn--visit" />
          </a>
        ) : null}
        {props.data.contentfulProject.images ? (
          <div style={{ gridColumn: "center-start/center-end" }}>
            <SRLWrapper>
              <div className={classes.gallery}>
                {props.data.contentfulProject.images.map(img => {
                  return (
                    <div className={classes.gallery__item}>
                      <Link to={img.file.url}>
                        <Img
                          fluid={img.fluid}
                          className={classes.gallery__item_img}
                          data-attribute="SRL"
                        ></Img>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </SRLWrapper>
          </div>
        ) : null}
      </section>
    </Layout>
  )
}
export default project
